import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface RegisterFormProps {
  onSubmit: (formData: { email: string; password: string }) => void;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: '#f0f0f0',
});

const RegisterCard = styled('div')({
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  width: '100%',
  maxWidth: '400px',
});

const Regist: React.FC<RegisterFormProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const validatePassword = (password: string): boolean => {
    return password.length >= 8 && /[A-Za-z]/.test(password) && /\d/.test(password);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const passwordIsValid = validatePassword(newPassword);

    if (!passwordIsValid) {
      setPasswordError('パスワードは8文字以上で、英字と数字を含む必要があります');
    } else {
      setPasswordError('');
    }

    if (newPassword === '' || !passwordIsValid) {
      setConfirmPassword(''); // パスワードが無効または空の場合、再入力もクリア
    }
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmValue = e.target.value;
    setConfirmPassword(confirmValue);
    setPasswordError(confirmValue !== password ? 'パスワードが一致しません' : '');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const passwordIsValid = validatePassword(password);

    if (!passwordIsValid) {
      setPasswordError('パスワードは8文字以上で、英字と数字を含む必要があります');
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError('パスワードが一致しません');
      return;
    }

    onSubmit({ email, password });
  };

  const isFormValid = () => {
    return (
      email.length > 0 &&
      validatePassword(password) &&
      confirmPassword === password
    );
  };

  return (
    <Container>
      <RegisterCard>
        <Typography variant="h5" gutterBottom>
          ユーザー登録
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="メールアドレス"
            variant="outlined"
            margin="normal"
            fullWidth
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="パスワード"
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            value={password}
            onChange={handlePasswordChange}
            error={!!passwordError}
            helperText={passwordError}
            required
          />
          {validatePassword(password) && (
            <TextField
              label="パスワード（再入力）"
              variant="outlined"
              margin="normal"
              fullWidth
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!!passwordError && confirmPassword.length > 0 && password !== confirmPassword}
              helperText={confirmPassword.length > 0 && password !== confirmPassword ? 'パスワードが一致しません' : ''}
              required
            />
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            style={{ marginTop: '16px' }}
            disabled={!isFormValid()}
          >
            登録
          </Button>
        </form>
      </RegisterCard>
    </Container>
  );
};

export default Regist;
