import React, { useState } from 'react';
import { Snackbar, Alert} from '@mui/material';
import RegisterForm from './components/Regist';
import { SnackbarCloseReason } from '@mui/material/Snackbar';
import { styled } from '@mui/system';

function App() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);

  const handleRegister = async (formData: { email: string; password: string }) => {
    try {
      const response = await fetch('/api/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSuccessMessage('仮登録しました！メールを確認して登録を完了してください。');
        setSuccessOpen(true); 
      } else {
        setErrorMessage('登録に失敗しました。もう一度試してください。');
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage('エラーが発生しました。後でもう一度お試しください。');
      setOpen(true);
    }
  };

  const handleCloseError = (event: React.SyntheticEvent<Element, Event> | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event: React.SyntheticEvent<Element, Event> | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };

  const Container = styled('div')({
    backgroundColor: 'transparent',
  });

  return (
    <Container>
      <RegisterForm onSubmit={handleRegister} />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
